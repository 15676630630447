/* eslint-disable */
import React from "react";
import { CircleTickMajor, InfoMinor } from "@shopify/polaris-icons";

export const generateContentStatus = status => {
  let content;

  switch (status) {
    case "start":
      content =
        "The optimization process can take several minutes depending on the quantity of images.";
      break;
    case "running":
      content =
        "All images of products, collections and blog posts are being automatically optimized. This process can take several minutes depending on the number of images.";
      break;
    case "completed":
      content =
        'The image optimization process has been completed. Please check the "History" section for details.';
      break;

    default:
      break;
  }

  return content;
};

export const generateStatus = status => {
  let content;

  switch (status) {
    case "start":
      content = "Start the process";
      break;
    case "running":
      content = "Processing";
      break;
    case "completed":
      content = "Processed";
      break;

    default:
      break;
  }

  return content;
};

export const generateIconBanner = statusBanner => {
  let icon;

  switch (statusBanner) {
    case "start":
      icon = InfoMinor;
      break;
    case "running":
      icon = <></>;
      break;
    case "completed":
      icon = CircleTickMajor;
      break;

    default:
      break;
  }

  return icon;
};

export const generateStatusBanner = statusBanner => {
  let status;

  switch (statusBanner) {
    case "start":
      status = "";
      break;
    case "running":
      status = "warning";
      break;
    case "completed":
      status = "success";
      break;

    default:
      break;
  }

  return status;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getUrlParams = params => {
  const v = window.location.search.match(
    new RegExp(`(?:[?&]${params}=)([^&]+)`)
  );
  return v ? v[1] : undefined;
};

export const getParamsUrlString = (url, params) => {
  const v = url && url.match(new RegExp(`(?:[?&]${params}=)([^&]+)`));
  return v ? v[1] : undefined;
};

export const getVal = str => {
  const v = window.location.search.match(new RegExp(`(?:[?&]${str}=)([^&]+)`));
  return v ? v[1] : undefined;
};

export const timeAgo = date => {
  const dates = new Date(date).getTime();
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  const suffix = " ago";

  const elapsed = Math.floor((Date.now() - dates) / 1000);

  if (elapsed < minute) {
    return "just now";
  }

  // get an array in the form of [number, string]
  const a = (elapsed < hour && [Math.floor(elapsed / minute), "minute"]) ||
    (elapsed < day && [Math.floor(elapsed / hour), "hour"]) ||
    (elapsed < month && [Math.floor(elapsed / day), "day"]) ||
    (elapsed < year && [Math.floor(elapsed / month), "month"]) || [
      Math.floor(elapsed / year),
      "year",
    ];

  // pluralise and append suffix
  return `${a[0]} ${a[1]}${a[0] === 1 ? "" : "s"} ${suffix}`;
};

export const formatDate = dates => {
  const newDate = dates || [];
  const data = newDate.map(date => {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(new Date(date));
  });

  return data;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDateTime = data => {
  const date = new Date(data);
  const year = date.getFullYear();
  let month = months[date.getMonth()];
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${month} ${dt}, ${year}`;
};

export const formatDateFullTime = data => {
  const date = new Date(data);
  const year = date.getFullYear();
  let month = months[date.getMonth()];
  let dt = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  if (hour < 10) {
    hour = `0${hour}`;
  }

  if (minute < 10) {
    minute = `0${minute}`;
  }

  return `${month} ${dt}, ${year} ${hour}:${minute}`;
};

export function getItemTitle(item) {
  switch (item.mediaType) {
    case 1:
      return item.productData ? item.productData.productTitle : "";
    case 2:
      return item.collectionData ? item.collectionData.collectionTitle : "";
    case 3:
      return item.articleData ? item.articleData.articleTitle : "";
    default:
      break;
  }
  return "Loading...";
}

export function getItemAdminUrl(item) {
  switch (item.mediaType) {
    case 1:
      return item.productData
        ? `/admin/products/${item.productData.productId}`
        : "";
    case 2:
      return item.collectionData
        ? `/admin/collections/${item.collectionData.collectionId}`
        : "";
    case 3:
      return item.articleData
        ? `/admin/articles/${item.articleData.articleId}`
        : "";
    default:
      break;
  }
  return "";
}

export function getItemShopUrl(item) {
  switch (item.mediaType) {
    case 1:
      return item.productData
        ? `/products/${item.productData.productHandle}`
        : "";
    case 2:
      return item.collectionData
        ? `/collections/${item.collectionData.collectionHandle}`
        : "";
    case 3:
      return item.articleData && item.blogData
        ? `/blogs/${item.blogData.blogHandle}/${item.articleData.articleHandle}`
        : "";
    default:
      break;
  }
  return "";
}
export const convertProductGrapQl = listData => {
  const newProductMap = new Map();
  listData.forEach(item => {
    if (item) {
      const productId = parseInt(item.id.replace("gid://shopify/Product/", ""));
      const data = {
        id: productId,
        featuredImage: item.featuredImage,
        title: item.title,
        handle: item.handle,
      };
      newProductMap.set(productId, data);
    }
  });
  return newProductMap;
};
export const convertCollectionGrapQl = listData => {
  const newProductMap = new Map();
  listData.forEach(item => {
    if (item) {
      const productId = parseInt(item.id.replace("gid://shopify/Collection/", ""));
      const data = {
        id: productId,
        featuredImage: {transformedSrc : item.image.url},
        title: item.title,
        handle: item.handle,
      };
      newProductMap.set(productId, data);
    }
  });
  return newProductMap;
};
export function convertCollectionToOptions(productCollections) {
  const collections =
    productCollections &&
    productCollections.edges &&
    productCollections.edges.map(item => {
      const node = item.node;
      const gid = node.id;
      const gidSplit = gid.split("/");
      const collectionId = gidSplit[gidSplit.length - 1];
      return {
        id: collectionId,
        title: node.title,
        featuredImage: node?.image,
        gid: node.id,
      };
    });
  return collections;
}
export function convertArticleToOptions(articles) {
const newArticles =
  articles?.edges
    ?.map(item => {
      const node = item.node;
      if (!node.image) return null; 
      const gidSplit = node.id.split("/");
      const articleId = gidSplit[gidSplit.length - 1];

      return {
        id: articleId,
        title: node.title,
        featuredImage: { originalSrc: node.image.url },
        gid: node.id,
      };
    })
    .filter(Boolean); 
    return newArticles 
}
export const convertToProductList = prods => {
  const productList =
    prods &&
    prods.edges &&
    prods.edges.map(item => {
      const node = item.node;
      const gid = node.id;
      const gidSplit = gid.split("/");
      const productId = gidSplit[gidSplit.length - 1];
      return {
        id: productId,
        title: node.title,
        featuredImage: {
          originalSrc: node?.featuredMedia?.preview?.image?.url,
        },
        gid: node.id,
      };
    });
  return productList;
};
export function formatFileSize(size) {
  if (size < 1024) {
    return `${size} bytes`; // Giữ nguyên Bytes
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`; // Chuyển sang KB
  }
  return `${(size / (1024 * 1024)).toFixed(2)} MB`; // Chuyển sang MB
}
